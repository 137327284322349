import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Box,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { supportTheme } from "theme";

interface IQueryOptionDB {
  _id: string;
  label: string;
  category: string
  url: string;
  type: string;
  description?: string;
}

interface IQueryOption {
  id: string;
  label: string;
  url: string;
  description?: string;
}

interface IQueryList {
  [category: string]: Array<IQueryOption>;
}

const Redash: React.FC = () => {
  const [RedashQueryList, setRedashQueryList] = useState<IQueryList>({});
  const adaptQueryList = (data: Array<IQueryOptionDB>, QueryList: IQueryList) => {
    data.forEach((item) => (
      QueryList[item.category] = []
    ))
    data.forEach((item) => (
      QueryList[item.category].push({ "id": item._id, "label": item.label, "url": item.url, "description": item.description })
    ))
    return QueryList
  }
  const fetchDB = async () => {
    const res = await fetch("/queries/all-redash");
    const RedashQueryListData = await res.json();
    if (RedashQueryListData.length > 0) {
      const RedashQueryListDataFormat: IQueryList = adaptQueryList(RedashQueryListData, {})
      setRedashQueryList(RedashQueryListDataFormat)
      const initialCategory = Object.keys(RedashQueryListDataFormat)[0]
      setCategory(initialCategory)
      setLabel(RedashQueryListDataFormat[initialCategory][0].label)
      setURL(RedashQueryListDataFormat[initialCategory][0].url)
    }
  };

  useEffect(() => {
    fetchDB();
  }, [])
  const [cloudName, setCloudName] = useState('');
  const [daysBackFrom, setdaysBackFrom] = useState('3');
  const [daysBackTo, setdaysBackTo] = useState('0');
  const [error, setError] = useState(''); // State for validation error message
  const [category, setCategory] = useState('');
  const [label, setLabel] = useState('');
  const [url, setURL] = useState('');

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const newCategory = event.target.value as string;
    setCategory(newCategory);
    setLabel(RedashQueryList[newCategory][0].label);
    setURL(RedashQueryList[newCategory][0].url);
  };

  const handleLabelChange = (event: SelectChangeEvent<string>) => {
    const newLabel = event.target.value as string;
    setLabel(newLabel);
    let queryItem = RedashQueryList[category].find((x) => x.label === newLabel);
    const newURL = (queryItem ? queryItem.url : "https://redash.cloudinary.com/queries/new")
    setURL(newURL)
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError(''); // Clear previous errors
    
    const from = parseInt(daysBackFrom, 10);
    const to = parseInt(daysBackTo, 10);

    // Validation logic for date range
    if (from - to > 3 || from - to < 0) {
      setError('The difference between "Days Back From" and "Days Back To" must be between 0 and 3 days.');
      return; // Stop form submission if validation fails
    }

    const regexp = /\{(.*?)\}/g;
    let rawUrl = url;
    let matches = url.match(regexp);
    if (matches){
      for (let match of matches){
        let matchStringNoBracket = `` + match.replace(/[{]/g, '${')
        rawUrl = rawUrl.replaceAll(match,matchStringNoBracket);
      }
    }
    //need to rewrite this when we have the DB
    let redashUrl = eval('`'+ rawUrl +'`');
    window.open(redashUrl, '_blank');
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/dashboard/links-grid');
  };

  const handleNumericInput = (value: string) => {
    return `` + value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  };

  const appTheme = supportTheme();
  return (
    <ThemeProvider theme={appTheme}>
      <Box mt={2}>
        <Button variant="contained" onClick={handleBackClick}>
          Back
        </Button>
      </Box>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="overline" component="h1" gutterBottom sx={{fontSize: "1.75rem"}}>
            Generate Redash Query
          </Typography>
          {(RedashQueryList && Object.keys(RedashQueryList).length > 0) &&
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel sx={{color: "#1d2438"}}>Category</InputLabel>
                <Select
                  value={category}
                  label="Category"
                  onChange={handleCategoryChange}
                >
                  {Object.keys(RedashQueryList).map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel sx={{color: "#1d2438"}}>Query/Dashboard</InputLabel>
                <Select
                  value={label}
                  label="Query/Dashboard"
                  onChange={handleLabelChange}
                  defaultValue=""
                >
                    {(Object.keys(RedashQueryList).length > 0) && RedashQueryList[category].map((opt) => (
                    <MenuItem key={opt.id} value={opt.label}>
                      {opt.label}
                      {opt.description && 
                      <Tooltip sx={{ fill: '#3448c5', width: '0.5em', height: '0.5em', marginLeft: '5px' }} title={opt.description}>
                          <InfoIcon />
                      </Tooltip>
                      }
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Conditionally render the following fields if "Bandwidth Dashboard" is selected */}
            {label === "Bandwidth dashboard" && (
              <>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Cloud Name"
                    value={cloudName}
                    onChange={(e) => setCloudName(e.target.value)}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Days Back From: "
                    value={daysBackFrom}
                    onChange={(e) => setdaysBackFrom(handleNumericInput(e.target.value))}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Days Back To:"
                    value={daysBackTo}
                    onChange={(e) => setdaysBackTo(handleNumericInput(e.target.value))}
                    required
                  />
                </Box>

                {/* Display validation error */}
                {error && (
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                )}

              </>
            )}
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        }
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Redash;
