import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Box,
  SelectChangeEvent
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { supportTheme } from "theme";

interface IQueryOptionDB {
  _id: string;
  label: string;
  category: string
  url: string;
  type: string;
  description?: string;
}

interface IQueryOption {
  id: string;
  label: string;
  url: string;
  description?: string;
}

interface IQueryList {
  [category: string]: Array<IQueryOption>;
}

const Coralogix: React.FC = () => {
  const [CoralogixQueryList, setCoralogixQueryList] = useState<IQueryList>({});
  const adaptQueryList = (data: Array<IQueryOptionDB>, QueryList: IQueryList) => {
    data.forEach((item) => (
      QueryList[item.category] = []
    ))
    data.forEach((item) => (
      QueryList[item.category].push({ "id": item._id, "label": item.label, "url": item.url, "description": item.description })
    ))
    return QueryList
  }
  const fetchDB = async () => {
    const res = await fetch("/queries/all-coralogix");
    const CoralogixQueryListData = await res.json();
    if (CoralogixQueryListData.length > 0) {
      const CoralogixQueryListDataFormat: IQueryList = adaptQueryList(CoralogixQueryListData, {})
      setCoralogixQueryList(CoralogixQueryListDataFormat)
      const initialCategory = Object.keys(CoralogixQueryListDataFormat)[0]
      setCategory(initialCategory)
      setLabel(CoralogixQueryListDataFormat[initialCategory][0].label)
      setURL(CoralogixQueryListDataFormat[initialCategory][0].url)
    }
  };

  useEffect(() => {
    fetchDB();
  }, [])
  const [cloudName, setCloudName] = useState('');
  const [timeRange, setTimeRange] = useState('7d');
  const [category, setCategory] = useState('');
  const [label, setLabel] = useState('');
  const [url, setURL] = useState('');

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const newCategory = event.target.value as string;
    setCategory(newCategory);
    setLabel(CoralogixQueryList[newCategory][0].label);
    setURL(CoralogixQueryList[newCategory][0].url);
  };

  const handleQueryChange = (event: SelectChangeEvent<string>) => {
    const newQuery = event.target.value as string;
    setLabel(newQuery);
    let queryItem = CoralogixQueryList[category].find((x) => x.label === newQuery);
    const newURL = (queryItem ? queryItem.url : `https://cloudinary-production.app.cx498.coralogix.com/opendashboards/app/discover#/?_g=(time:(from:now-${timeRange},to:now))&_a=(query:(query_string:(query:'${label} AND cloud_name:${cloudName}')))`)
    setURL(newURL);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const regexp = /\{(.*?)\}/g;
    let rawUrl = url;
    let matches = url.match(regexp);
    if (matches){
      for (let match of matches){
        let matchStringNoBracket = `` + match.replace(/[{]/g,'${')
        rawUrl = rawUrl.replaceAll(match,matchStringNoBracket);
      }
    }
    //need to rewrite this when we have the DB
    let CoralogixUrl = eval('`'+ rawUrl +'`');
    window.open(CoralogixUrl, '_blank');
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/dashboard/links-grid');
  };

  const appTheme = supportTheme();
  return (
    <ThemeProvider theme={appTheme}>
        <Box mt={2}>
            <Button variant="contained" onClick={handleBackClick}>
              Back
            </Button>
        </Box>
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="overline" component="h1" gutterBottom sx={{fontSize: "1.75rem"}}>
          Generate Coralogix Query
        </Typography>
          {(CoralogixQueryList && Object.keys(CoralogixQueryList).length > 0) &&
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Cloud Name"
              value={cloudName}
              onChange={(e) => setCloudName(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel sx={{color: "#1d2438"}}>Time Range</InputLabel>
              <Select
                value={timeRange}
                label="Time Range"
                onChange={(e) => setTimeRange(e.target.value as string)}
              >
                <MenuItem value="30d">Last 30 days</MenuItem>
                <MenuItem value="7d">Last 7 days</MenuItem>
                <MenuItem value="24h">Last 24 hours</MenuItem>
                <MenuItem value="12h">Last 12 hours</MenuItem>
                <MenuItem value="4h">Last 4 hours</MenuItem>
                <MenuItem value="1h">Last 1 hour</MenuItem>
                <MenuItem value="30m">Last 30 minutes</MenuItem>
                <MenuItem value="15m">Last 15 minutes</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel sx={{color: "#1d2438"}}>Category</InputLabel>
              <Select
                value={category}
                label="Category"
                onChange ={handleCategoryChange}
              >
                {Object.keys(CoralogixQueryList).map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel sx={{color: "#1d2438"}}>Query</InputLabel>
              <Select
                value={label}
                label="Query"
                onChange={handleQueryChange}
              >
                {CoralogixQueryList[category].map((opt) => (
                  <MenuItem key={opt.label} value={opt.label}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
        }
      </Box>
    </Container>
    </ThemeProvider>
  );
};

export default Coralogix;