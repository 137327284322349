import React, { useState, useEffect } from 'react'
import {
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Box,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { supportTheme } from "theme";
import { useNavigate } from 'react-router-dom';

interface IQueryOptionDB {
  _id: string;
  label: string;
  category: string
  url: string;
  type: string;
  description?: string;
}

interface IQueryOption {
  id: string;
  label: string;
  url: string;
  description?: string;
}

interface IQueryList {
  [category: string]: Array<IQueryOption>;
}

const Jenkins: React.FC = () => {
  const [JenkinsQueryList, setJenkinsQueryList] = useState<IQueryList>({});
  const adaptQueryList = (data: Array<IQueryOptionDB>, QueryList: IQueryList) => {
    data.forEach((item) => (
      QueryList[item.category] = []
    ))
    data.forEach((item) => (
      QueryList[item.category].push({ "id": item._id, "label": item.label, "url": item.url, "description": item.description })
    ))
    return QueryList
  }
  const fetchDB = async () => {
    const res = await fetch("/queries/all-jenkins");
    const JenkinsQueryListData = await res.json();
    if (JenkinsQueryListData.length>0){
      const JenkinsQueryListDataFormat: IQueryList = adaptQueryList(JenkinsQueryListData, {})
      setJenkinsQueryList(JenkinsQueryListDataFormat)
      const initialCategory = Object.keys(JenkinsQueryListDataFormat)[0]
      setCategory(initialCategory)
      setLabel(JenkinsQueryListDataFormat[initialCategory][0].id)
      setURL(JenkinsQueryListDataFormat[initialCategory][0].url)
    } 
  };

  useEffect(() => {
    fetchDB();
  }, [])
  const [category, setCategory] = useState('');
  const [label, setLabel] = useState('');
  const [url, setURL] = useState('');

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const newCategory = event.target.value as string;
    setCategory(newCategory);
    setLabel(JenkinsQueryList[newCategory][0].id);
    setURL(JenkinsQueryList[newCategory][0].url);
  };

  const handleLabelChange = (event: SelectChangeEvent<string>) => {
    const newLabel = event.target.value as string;
    setLabel(newLabel);
    let queryItem = JenkinsQueryList[category].find((x) => x.label === newLabel);
    const newURL = (queryItem ? queryItem.url : "https://jenkins.cloudinary.com")
    setURL(newURL);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let jenkinsUrl = url;
    window.open(jenkinsUrl, '_blank');
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/dashboard/links-grid');
  };

  const appTheme = supportTheme();
  return (
    <ThemeProvider theme={appTheme}>
        <Box mt={2}>
            <Button variant="contained" onClick={handleBackClick}>
              Back
            </Button>
        </Box>
      <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="overline" component="h1" gutterBottom sx={{fontSize: "1.75rem"}}>
          Generate Jenkins URLs
        </Typography>
          {(JenkinsQueryList && Object.keys(JenkinsQueryList).length >0) &&
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel sx={{color: "#1d2438"}}>Category</InputLabel>
              <Select
                value={category}
                label="Category"
                onChange ={handleCategoryChange}
              >
                {Object.keys(JenkinsQueryList).map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel sx={{color: "#1d2438"}}>Query</InputLabel>
              <Select
                value={label}
                label="Query"
                onChange={handleLabelChange}
              >
                {(Object.keys(JenkinsQueryList).length > 0) && JenkinsQueryList[category].map((opt) => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.label}
                    {opt.description && 
                    <Tooltip sx={{ fill: '#3448c5', width: '0.5em', height: '0.5em', marginLeft: '5px' }} title={opt.description}>
                      <InfoIcon />
                    </Tooltip>
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
          }
      </Box>
    </Container>
    </ThemeProvider>
   
  );
};

export default Jenkins;
